<template>
  <div>
    <div id="knives-prizes" class="knives-id"></div>
    <intersect @enter="getAnchor('prizes')" @leave="getAnchor(null)">
      <div class="knives__prizes">
        <div class="container">
          <h3 class="knives__prizes-title knives__block-title">
            {{ $t("knives.prizesTitle") }}
          </h3>
          <div
            class="knives__prizes-text"
            v-html="$t('knives.prizesText')"
          ></div>
        </div>
      </div>
    </intersect>
  </div>
</template>

<script>
import Intersect from "vue-intersect";
import { mapActions } from "vuex";
import { actionTypes } from "@/store/modules/knives";
export default {
  name: "KnivesPrizes",
  components: {
    Intersect,
  },

  methods: {
    ...mapActions("knivesModule", {
      getAnchor: actionTypes.getAnchor,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
